// function getBloxAPIUrl() {
//   console.log('CONFIG: BLOX_ENV: ' + process.env.REACT_APP_BLOX_ENV)
//   var APIBaseURL = process.env.REACT_APP_BLOX_API_URL_PRODUCTION
//   if (process.env.REACT_APP_BLOX_ENV === 'demo') {
//      APIBaseURL = process.env.REACT_APP_BLOX_API_URL_DEMO
//      console.log('CONFIG: Blox API URL: demo')
//   }
//   if (process.env.REACT_APP_BLOX_ENV === 'staging') {
//      APIBaseURL = process.env.REACT_APP_BLOX_API_URL_STAGING
//      console.log('CONFIG: Blox API URL: staging')
//   }
//   if (process.env.REACT_APP_BLOX_ENV === 'development') {
//      APIBaseURL = process.env.REACT_APP_BLOX_API_URL_LOCAL
//      console.log('CONFIG: Blox API URL: development')
//   }
//   if (process.env.REACT_APP_BLOX_ENV === undefined) {
//     APIBaseURL = process.env.REACT_APP_BLOX_API_URL_LOCAL
//     console.log('CONFIG: Blox API URL: development')
//   }
//   return APIBaseURL
// }

function getBloxAPIUrl() {
  var APIBaseURL = ''
  if(process.env.REACT_APP_BLOX_API_URL=== undefined){
    // APIBaseURL = "https://api-dev.thebloxoffice.com/api"
    APIBaseURL = "http://10.0.0.232:8001/api"
    // APIBaseURL = "https://api-staging.thebloxoffice.com/api"
  }else{
    APIBaseURL = 'https://' + process.env.REACT_APP_BLOX_API_URL +'/api'
  }
  return APIBaseURL
}

function getStripeAPIKey() {
  var APIKey = process.env.REACT_APP_STRIPE_API_KEY_TEST
  if (process.env.REACT_APP_BLOX_ENV === 'production') {
    APIKey = process.env.REACT_APP_STRIPE_API_KEY_LIVE
  } else {
    APIKey = process.env.REACT_APP_STRIPE_API_KEY_TEST
  }
  return 'pk_live_90YLPwA74cAqNzoAZknLielc'
  // return 'pk_test_LYOQsvM1crKxcfHZSZU2Nk9t'
  return APIKey
}


export {
  getBloxAPIUrl,
  getStripeAPIKey
}
